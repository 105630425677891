
import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Button from '../../../components/Button';

import Layout from '../../../components/layout/Layoutexport-en';



import { Link } from 'gatsby';






const seo={
  title: "Professional Hygiene products for Export",
  desc:  "We manufacture hygienic solutions of the best quality for the whole region and the world",
  image: "productos.jpg",
  pathname: "/export/en/products/",
  alternateEn: "/export/en/products/",
  alternateEs: "/export/es/productos/",
  alternateEsec: "/productos/",
  alternateDefault: "/export/es/productos/",

}

const siteVerOpts = [

  {
    label: 'International',
    value: '',

  },


  {
    label: 'Ecuador',
    value: '/productos/',

  },


];

const langOpts = [

  {
    label: 'EN',
    value: '',

  },


  {
    label: 'ES',
    value: '/export/es/productos/',

  },


];

const Products = ({location}) => (

          <Layout location={location} crumbLabel="Products for export"
          alternates alternateEn={seo.alternateEn} alternateEs={seo.alternateEs} alternateEsec={seo.alternateEsec} alternateDefault={seo.alternateDefault}
                  siteVerOpts={siteVerOpts} langOpts={langOpts}
                  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname} pageLang={seo.pageLang}>

<section id="productos" className="mt-14">
              <div className="">
               <h1 className="text-5xl font-semibold text-center mb-2">Professional Hygiene Products </h1>
                <h2 className="text-4xl font-semibold text-center mb-8 text-primary-lighter">We manufacture and export products of international quality </h2>
              </div>
            </section>






<section id="productos" className="mt-14">
<div className="relative bg-blue-900">
  <div className="h-56 bg-blue-900 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../../../images/productos-de-higiene-profesional-para-exportacion.jpg"
                      className="flex justify-center "
                      alt="Productos de higiene de alta calidad para exportación"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-28">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <p className=" text-2xl text-leftfont-normal text-white pb-6 pt-8">We manufacture the highest quality hygiene products for the most demanding markets and industries. <br/><br/>  Our constant innovation has allowed us to enter markets in the region. <br/><br/> Our plant located in Quito-Ecuador, has all the certifications and recognitions required to access the most demanding markets, such as BPM, BPA, ISO 9001.</p>
    </div>
  </div>
</div>
</section>



<section id="categorias productos" className=" mt-20  ">
   <h2 className="text-primary-default  title-font text-center text-5xl font-semibold pb-10 ">Export Product Offering </h2>
      

    <div className="relative bg-white px-4 sm:px-6 lg:pt-6 lg:pb-28 lg:px-8  ">
     <div className="relative max-w-7xl mx-auto mt-10">
      <div className=" max-w-lg mx-auto grid gap-8 lg:grid-cols-3 lg:max-w-none">

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <StaticImage src="../../../images/categoria-dispensadores.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestra amplia línea de dispensadores"
                    placeholder="blurred"
                    layout="constrained"/>
                <div className="flex flex-wrap mx-auto justify-center">
                  <Link to="/export/en/dispensers/" className="text-3xl font-semibold text-lime-500 text-center leading-relaxed">Dispensers</Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Sturdy and elegant equipment for dispensing toilet paper, soap, gel, hand towels, napkins.</p>
               <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/export/en/dispensers/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">More information
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
              </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <StaticImage src="../../../images/categoria-higiene-de-manos.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestros productos profesionales para higiene de manos"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center">
                  <Link to="/export/en/hand-hygiene/" className="text-3xl font-semibold text-lime-500 text-center leading-relaxed">Hand Hygiene</Link>
                </div>
              <p className="text-primary-default text-lg pt-2">High quality products for hand washing and disinfection: antibacterial gel, soap, alcohol.</p>
              <div className="flex flex-wrap mx-auto justify-left">
                <Link to="/export/en/hand-hygiene/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">More information
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../../../images/categoria-industria-alimenticia.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestros productos de alta calidad para la industria alimenticia"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <Link to="/export/en/food-industry/" className="text-3xl font-semibold text-lime-500 text-center">Food Industry</Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Highly concentrated chemicals for surface disinfection.</p>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/export/en/food-industry/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">More information
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../../../images/categoria-desinfeccion-de-superficies.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestros productos de alta calidad para la desinfección de superficies"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <Link to="/export/en/surface-disinfection/" className="text-3xl font-semibold text-lime-500 text-center">Surface Disinfection</Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Highly concentrated chemicals for surface disinfection.</p>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/export/en/surface-disinfection/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">More information
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../../../images/categoria-absorbentes.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestros absorbentes fabricados con materia prima de calidad"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <Link to="/export/en/tissues/" className="text-3xl font-semibold text-lime-500 text-center">Tissues</Link>
                </div>
              <p className="text-primary-default text-lg pt-2">Tissues manufactured with first quality raw materials that guarantee absorption, softness and resistance.</p>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/export/en/tissues/" className="mt-4 text-primary-lighter inline-flex items-center text-lg text-semibold">More information
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div>
        </div>
      </div>

     </div>
    </div>
    <div className="pt-20 ">
        <a href="../../../files/catalogo-exportacion-unilimpio.pdf" target="_blank" rel="noopener">
              <Button className="flex mx-auto text-white bg-primary-lighter border-0 py-2 px-8 rounded transition duration-200 ease-in-out bg-primary-default shadow-md hover:bg-secondary-lighter hover:shadow-lg transform hover:-translate-y-1 hover:scale-105 focus:outline-none"  >DOWNLOAD OUR PRODUCT CATALOG </Button></a>
      </div>
  </div>
</section>



<section id="contacto" className="mt-4">
<div className="relative bg-lime-500">
  <div className="h-56  sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../../../images/call-center-unilimpio-export.jpg"
                      className=" object-cover"
                      alt="Solicite una cotización con nosotros"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-20">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <h2 className="mt-4 text-xl sm:text-4xl md:text-4xl font-bold text-white text-center ">¡REQUEST A QUOTE NOW!</h2>
      <p className="mt-6 text-2xl text-left font-normal text-white">Thank you for your interest in Unilimpio S.A. We will be pleased to provide you with more information about our professional hygiene products.</p>
      <div className="mt-6">
      <div className="flex flex-wrap mx-auto justify-center pt-4">
         <Link to="/export/en/contact/">
          <Button className=" text-primary-lighter text-semibold text-xl  rounded-xl transition duration-200 ease-in-out bg-white shadow-md hover:bg-white hover:shadow-lg transform hover:-translate-y-1 hover:scale-105 focus:outline-none" size="s" type="submit">Quote</Button>
         </Link>
       </div>
      </div>
    </div>
  </div>
</div>
</section>


          </Layout>

)
export default Products
